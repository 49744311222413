import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Flex, Text } from 'rebass/styled-components';

import Layout from '../components/Layout';

const NotFoundPage: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>Not Found</title>
      </Helmet>
      <Flex
        flex={1}
        flexDirection="column"
        pt={125}
        sx={{ height: '100%', minHeight: 400 }}
        justifyContent="center"
        alignItems="center"
      >
        <Text fontSize={5} fontWeight={700} mb={3}>
          404: Not Found
        </Text>
        <Text fontSize={3}>The page you requested could not be found</Text>
      </Flex>
    </Layout>
  );
};

export default withPrismicUnpublishedPreview(NotFoundPage);
